
import { Component, Vue } from 'vue-property-decorator'
import { DetailInfo } from '@/types/advertising'
@Component({
  name: 'AdvertisingList'
})
export default class extends Vue {
  private searchInfo = {
    projectName: ''
  }

  private loading = false
  private tableData: DetailInfo[] = []

  private total = 0
  private size = 10
  private page = 1

  created () {
    this.getList()
  }

  // 新增广告
  onAdd () {
    this.$router.push({ path: '/advertisingList/Add' })
  }

  // 查看广告详情
  onDetail (advertiseId: string) {
    this.$router.push({ name: 'advertisingDetail', params: { advertiseId } })
  }

  // 编辑广告
  onEdit (advertiseId: string) {
    this.$router.push({ name: 'advertisingEdit', params: { advertiseId } })
  }

  // 删除广告
  onDelete (advertiseId: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.$axios
          .post(this.$apis.advertising.deleteAdvertise, { advertiseId })
          .then(() => {
            this.$message.success('删除成功')
            this.searchList()
          })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  // 发布广告 下架广告
  onRelease (advertiseId: string, status: string) {
    const text = status === '0' ? '发布' : '下架'
    this.$confirm(`确认${text}吗, 是否继续?`, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.$axios.post(this.$apis.advertising.releaseAdvertise, { advertiseId }).then(() => {
          this.$message.success(`${text}成功`)
          this.searchList()
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  // 查询
  searchList () {
    this.page = 1
    this.getList()
  }

  // 获取列表数据
  getList () {
    this.loading = true

    this.$axios
      .get<{ total: number; list: DetailInfo[] }>(
        this.$apis.advertising.selectAdvertiseByPage,
        {
          page: this.page,
          size: this.size,
          ...this.searchInfo
        }
      )
      .then((res) => {
        if (res) {
          this.total = res.total || 0
          this.tableData = res.list || []
        }
      })
      .finally(() => {
        this.loading = false
      })
  }

  // 广告状态
  getDataStatus (status: string) {
    const res: { [key: string ]: string } = {
      0: '待发布',
      1: '发布',
      2: '下架'
    }

    return res[status]
  }
}
